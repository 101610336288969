















import Vue from "vue";
import { EventCardStatus } from "@/types";

interface EventCardChip {
  status: EventCardStatus;
  text: string;
  color: string;
}

const EVENT_CARD_CHIPS: EventCardChip[] = [
  {
    status: "closed",
    text: "Реєстрацію завершено",
    color: "purple lighten-2"
  },
  {
    status: "applied",
    text: "Очікує підтвердження",
    color: "purple lighten-2"
  },
  {
    status: "confirmed",
    text: "Участь затверджено",
    color: "green darken-2"
  },
  {
    status: "examFailed",
    text: "Тест не пройдено",
    color: "purple lighten-2"
  },
  {
    status: "awarded",
    text: "Години нараховано",
    color: "green darken-2"
  }
];

export default Vue.extend({
  props: {
    status: {
      type: Object as () => Record<EventCardStatus, boolean>,
      required: true
    }
  },

  computed: {
    chips(): EventCardChip[] {
      return EVENT_CARD_CHIPS.filter(chip => this.status[chip.status]);
    }
  }
});
