import { getYear } from "date-fns";
import { Resource, NavItem } from "@/types";

export const ERAU_BASE_URL = process.env.VUE_APP_ERAU_BASE_URL;

export const NOTIFICATION_LIFE_SPAN = 4000;

export const CURRENT_YEAR = getYear(new Date());

export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const NAV_ITEMS: NavItem[] = [
  {
    path: "/dashboard",
    title: "Центр акредитації ВША НААУ",
    label: "Головна",
    icon: "mdi-school-outline"
  },
  {
    path: "/qr-codes",
    title: "QR коди",
    label: "QR коди",
    icon: "mdi-qrcode"
  },
  {
    path: "/exams",
    title: "Тести",
    label: "Тести",
    icon: "mdi-help-circle-outline"
  },
  {
    path: "/points",
    title: "Залікові Години та сертифікати",
    label: "Залікові Години",
    icon: "mdi-star-outline"
  },
  {
    path: "/profile",
    title: "Профіль",
    label: "Профіль",
    icon: "mdi-account-outline"
  }
];

export const EVENTS_PER_PAGE = 5;

export const POINTS_PER_PAGE = 5;

export const PRONOUNS = {
  ["Захід" as Resource]: "який",
  ["Сторінка" as Resource]: "яку"
};

export const DB_DATE_FORMATS = ["dd.MM.yyyy", "yyyy-MM-dd HH:mm:ss"];

export const FIRST_YEAR = 2019;

export const CUSTOM_OPTION_TAG = "[ваш варіант]";

export const TEXT_TAG = "[текст]";

export const STALE_DAYS = 15;
