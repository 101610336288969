






















































import Vue from "vue";
import DashboardCard from "@/components/DashboardCard.vue";
import QuoteCard from "@/components/QuoteCard.vue";
import EventRepository from "@/repositories/EventRepository";
import { Event, EventsType } from "@/types";
import { mapGetters, mapActions, mapState } from "vuex";
import { isFeedbackDateExpired } from "@/helpers/DateHelper";

export default Vue.extend({
  data() {
    return {
      feedbacks: [] as Event[],
      exams: [] as Event[]
    };
  },

  computed: {
    ...mapGetters("dashboard", ["getRefusedFeedbackIds"]),
    ...mapState("event", ["filter"])
  },

  methods: {
    ...mapActions("dashboard", ["refuseFeedback"]),
    ...mapActions("event", ["setFilter", "fetchEvents", "setEventsType"]),

    feedbackRefuse(feedback: Event) {
      const { id, end } = feedback;
      this.refuseFeedback({ id, end });
      this.feedbacks = this.feedbacks.filter(feedback => feedback.id !== id);
    },

    onEventSearch(anytimeFilter: { anytime: boolean }) {
      const filter = {
        ...this.filter,
        ...anytimeFilter
      };
      this.setFilter(filter);
      const eventsType = "active" as EventsType;
      this.setEventsType({ eventsType });
      this.fetchEvents();
      this.$router.push(`/events`);
    }
  },

  async mounted() {
    const payload = await EventRepository.getEvents("passed", 5, 1, {});
    if (payload) {
      const { events } = payload;

      this.exams = events.filter(
        event => event.examStatus !== null && event.examStatus !== "passed"
      );

      this.feedbacks = events.filter(
        event =>
          event.feedbackStatus !== null &&
          event.feedbackStatus !== "passed" &&
          !(this.getRefusedFeedbackIds as number[]).some(
            id => id === event.id
          ) &&
          !isFeedbackDateExpired(event.end)
      );
    }
  },

  components: {
    DashboardCard,
    QuoteCard
  }
});
